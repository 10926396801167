import React, { useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { Image } from 'semantic-ui-react';
import HeaderComponent from "../components/common/header";
import Footer from "../components/common/footer";
import '../styles/allPorcelain.css';

const options = [
  { title: 'Marble Look', id: 'marble', img: 'https://i.postimg.cc/fWVJN30R/1.png' },
  { title: 'Concrete Look', id: 'concrete', img: 'https://i.postimg.cc/Pxghh5BC/3.png' },
  { title: 'Wood Look', id: 'wood', img: 'https://i.postimg.cc/FzXt8nLc/4.png' },
  { title: 'Stone Look', id: 'stone', img: 'https://i.postimg.cc/YqcqBdHd/2.png' },
];

const AllPorcelainPage = ({ data }) => {
  const { allContentfulLooks } = data;

  useEffect(() => {
    const handleScroll = () => {
      const backToTopButton = document.getElementById('back-to-top');
      if (window.scrollY > 300) {
        backToTopButton.style.display = 'block';
      } else {
        backToTopButton.style.display = 'none';
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="all-porcelain-page">
      <HeaderComponent page="product" />
      <div className="banner">
        <p className='page-title'>Porcelain</p>
        <div className="anchor-navigation">
          {options.map((option, index) => (
            <a href={`#${option.id}`} key={index} className="anchor-item">
              <img src={option.img} alt={option.title} className="anchor-image" />
              <p className="anchor-title">{option.title}</p>
            </a>
          ))}
        </div>
      </div>
      <div className="porcelain-grid">
        {allContentfulLooks.nodes.map((look, index) => (
          <div key={index} id={look.slug} className="porcelain-item">
            <p className='look-title'>{look.title}</p>
            <div className="series-group">
              {look.series.map((series, idx) => (
                <Link to={`/series/${series.slug}`} key={idx} className="series-item">
                  {series.seriesImage && series.seriesImage.length > 0 && series.seriesImage[0].fluid && (
                    <Image src={series.seriesImage[0].fluid.src} className="series-image" />
                  )}
                  <div className="series-title">{series.seriesTitle}</div>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
      <Footer />
      <button id="back-to-top" onClick={scrollToTop}>Back to Top</button>
    </div>
  );
};

export const query = graphql`
  query {
    allContentfulLooks(filter: { slug: { in: ["marble", "wood", "concrete", "stone"] } }) {
      nodes {
        title
        slug
        series {
          id
          seriesTitle
          slug
          seriesImage {
            fluid(maxWidth: 4000) {
              src
            }
          }
        }
      }
    }
  }
`;

export default AllPorcelainPage;
